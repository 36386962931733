@use "@/mixins";

:root {
    --primary-1: 0deg, 0%, 10%;
    --primary-2: 0deg, 0%, 14%;
    --primary-3: 0deg, 0%, 20%;

    --secondary-1: 0deg, 0%, 95%;
    --secondary-2: 0deg, 0%, 71%;

    --accent-1: 193deg, 95%, 68%;

    --header-height: 60px;
    --header-gap: 21px;
}

* {
    margin: 0;
    padding: 0;
    border: 0;
    font-family: inherit;
    font-size: 100%;
    box-sizing: border-box;
}

ol,
ul {
    list-style: none;
}

a,
button {
    color: inherit;
    font-weight: inherit;
    text-decoration: none;
    background-color: transparent;
    -webkit-tap-highlight-color: transparent;
    cursor: pointer;
}

p {
    line-height: 1.4;
}

progress,
input:is([type="checkbox"], [type="radio"], [type="range"]) {
    accent-color: hsl(var(--accent-1));
}

textarea {
    resize: vertical;
}

html {
    min-width: 350px;
    scroll-behavior: smooth;

    $dot-color: hsl(0deg, 0%, 85%, 0.25);
    background-image: linear-gradient(
        90deg,
        $dot-color 40%,
        hsl(var(--accent-1)) 50%,
        $dot-color 60%
    );
    background-color: hsl(var(--primary-1));
    background-size: 220%;
    background-attachment: fixed;
    animation: gradient 8s linear infinite;

    @keyframes gradient {
        0% {
            background-position: 110% 50%;
        }
        35% {
            background-position: 110% 50%;
        }
        65% {
            background-position: -10% 50%;
        }
        100% {
            background-position: -10% 50%;
        }
    }

    &.sr .reveal-hidden {
        visibility: hidden;
    }

    body {
        min-height: 100dvh;
        display: flex;
        flex-direction: column;

        background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABYAAAAWCAYAAADEtGw7AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAABASURBVHgB7ZKxDQAgEAJf4hTfuf9QboLGBcxbUMkldORCQcvMFQIQIiy2+CsxyXFS7csW92oRwIwH/AqLLb6wAXO8CJYmG0HTAAAAAElFTkSuQmCC");
        background-size: 20px;
        background-attachment: fixed;
        image-rendering: pixelated;

        color: hsl(var(--secondary-1));
        font-size: 16px;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;

        main {
            flex-grow: 1;
            display: flex;
            flex-direction: column;
            gap: 40px;
        }
    }
}

::-webkit-scrollbar {
    @include mixins.size(6px);
    background-color: hsl(var(--primary-1));
}

::-webkit-scrollbar-thumb {
    background-color: hsl(0deg, 0%, 35%);
    border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
    background-color: hsl(0deg, 0%, 25%);
}

::-webkit-scrollbar-corner {
    background-color: hsl(var(--primary-1));
}

::selection {
    background-color: hsl(var(--secondary-1), 0.2);
}
