@use "@/mixins";

.dropdown {
    --item-padding: 8px 16px;
    position: relative;
    height: 100%;
    display: flex;
    align-items: stretch;

    .button {
        padding: var(--item-padding);
        display: flex;
        align-items: center;
        gap: 8px;
        transition: 0.3s color;

        &:hover {
            color: hsl(var(--accent-1));
        }

        svg {
            transition: rotate 0.2s;
        }
    }

    .menu {
        position: absolute;
        top: 100%;
        left: 0;
        min-width: 100%;
        border-radius: 0 0 8px 8px;
        transform-origin: top;
        transition:
            0.15s opacity,
            0.3s scale;

        li {
            a {
                padding: var(--item-padding);
                @include mixins.size();
                display: flex;
                align-items: center;
                transition: 0.3s color;

                &:hover {
                    color: hsl(var(--accent-1));
                }
            }

            &:last-child {
                border-radius: inherit;
            }
        }
    }

    &.open {
        .button svg {
            rotate: 180deg;
        }

        .menu {
            opacity: 1;
            scale: 1;
        }
    }

    &:not(.open) {
        .menu {
            opacity: 0;
            scale: 0;
        }
    }
}
